import { CompanyInfo } from "../models";

export const companyInfo: CompanyInfo = {
  tax_id: '',
  ein: '',
  accepts_assignment: false,
  name: '',
  street: '',
  city: '',
  state: '',
  zip_code: '',
  phone: '',
  npi: '',
  taxonomy_number: ''
}