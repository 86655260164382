import { AppointmentCancelledWithFee } from "../models";

export const appointmentCancelledWithFee: AppointmentCancelledWithFee = {
  doctor: null,
  client: null,
  start_at: null,
  end_at: null,
  cancelled_at: null,
  type: 1,
  procedure_codes: [
    {
      id: null,
      modifier_a: null,
      modifier_b: null,
      units: 1,
      charge: null,
      payout: null,
      max_charge: null,
    },
  ],
  cancelled_charge_data: {
    patient_charge: null,
    payout: null,
    cancellation_with_fee: null,
    cancellation_without_fee: null,
  },
  cancelled_charge_data_placeholder: {
    patient_charge: null,
    payout: null,
    cancellation_with_fee: null,
    cancellation_without_fee: null,
  },
  is_insurance: false,
  follow_up_note: null,
  disapproved_reason: null,
};
