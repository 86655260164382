import React, { useContext, useState } from 'react'
import { provider as initialProvider } from '../data'
import { Provider } from '../models';

// ---------- ProviderContext ----------
type ProviderSetter = (provider: Provider) => void;
const ProviderContext = React.createContext<Provider | undefined>(undefined);
const ProviderSetterContext = React.createContext<ProviderSetter | undefined>(
    undefined
);

// ---------- Hooks ----------
function useProvider(): Provider {
    const provider = useContext(ProviderContext);
    if (provider === undefined) {
        /* istanbul ignore next */
        throw new Error('useProvider must be used within a ProviderContextProvider');
    }
    return provider;
}

function useProviderSetter(): ProviderSetter {
    const setProvider = useContext(ProviderSetterContext);
    if (setProvider === undefined) {
        throw new Error(
            'useProviderSetter must be used within a ProviderContextProvider'
        );
    }
    return setProvider;
}

// ---------- ProviderContextProvider ----------
const ProviderContextProvider: React.FC = ({ children }) => {
    const [provider, setProvider] = useState<Provider>(initialProvider);

    return (
        <ProviderContext.Provider value={provider}>
            <ProviderSetterContext.Provider value={setProvider}>
                {children}
            </ProviderSetterContext.Provider>
        </ProviderContext.Provider>
    );
};

export { ProviderContextProvider, useProvider, useProviderSetter };