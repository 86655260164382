import { Contact } from '../models'

export const contact: Contact = {
  first_name: '',
  last_name: '',
  middle_name: '',
  suffix: '',
  nickname: '',
  email: '',
  phone: '',
  notification_email: true,
  notification_text: false,
  notification_voice: true,
  street: '',
  city: '',
  state: '',
  zip_code: '',
  notes: ''
}