import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider, ProviderContextProvider, ClientContextProvider } from 'src/contexts';
import * as serviceWorker from 'src/serviceWorker';
import { AuthProvider } from 'react-auth-kit'

ReactDOM.render(
    <AuthProvider
        authType={'localstorage'}
        authName={'_auth'}
        // cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}>
      <HelmetProvider>
        <SidebarProvider>
            <ProviderContextProvider>
              <ClientContextProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </ClientContextProvider>
            </ProviderContextProvider>
        </SidebarProvider>
      </HelmetProvider>
    </AuthProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
