import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";

import SidebarLayout from "src/layouts/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";

import SuspenseLoader from "src/components/SuspenseLoader";
import { RequireAuth } from "react-auth-kit";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import("src/content/overview")));

// Dashboards

const Crypto = Loader(lazy(() => import("src/content/dashboards/Crypto")));

// Applications

const Messenger = Loader(
  lazy(() => import("src/content/applications/Messenger"))
);
const Transactions = Loader(
  lazy(() => import("src/content/applications/Transactions"))
);
const UserProfile = Loader(
  lazy(() => import("src/content/applications/Users/profile"))
);
const UserSettings = Loader(
  lazy(() => import("src/content/applications/Users/settings"))
);

// Components

const Buttons = Loader(
  lazy(() => import("src/content/pages/protected/Components/Buttons"))
);
const Modals = Loader(
  lazy(() => import("src/content/pages/protected/Components/Modals"))
);
const Accordions = Loader(
  lazy(() => import("src/content/pages/protected/Components/Accordions"))
);
const Tabs = Loader(
  lazy(() => import("src/content/pages/protected/Components/Tabs"))
);
const Badges = Loader(
  lazy(() => import("src/content/pages/protected/Components/Badges"))
);
const Tooltips = Loader(
  lazy(() => import("src/content/pages/protected/Components/Tooltips"))
);
const Avatars = Loader(
  lazy(() => import("src/content/pages/protected/Components/Avatars"))
);
const Cards = Loader(
  lazy(() => import("src/content/pages/protected/Components/Cards"))
);
const Forms = Loader(
  lazy(() => import("src/content/pages/protected/Components/Forms"))
);

// Status

const Status404 = Loader(
  lazy(() => import("src/content/pages/protected/Status/Status404"))
);
const Status500 = Loader(
  lazy(() => import("src/content/pages/protected/Status/Status500"))
);
const StatusComingSoon = Loader(
  lazy(() => import("src/content/pages/protected/Status/ComingSoon"))
);
const StatusMaintenance = Loader(
  lazy(() => import("src/content/pages/protected/Status/Maintenance"))
);

// Providers
const Providers = Loader(
  lazy(() => import("src/content/pages/protected/Providers"))
);

const ProvidersCreate = Loader(
  lazy(() => import("src/content/pages/protected/Providers/create"))
);

const ProvidersUpdate = Loader(
  lazy(() => import("src/content/pages/protected/Providers/update"))
);

// Clients
const Clients = Loader(
  lazy(() => import("src/content/pages/protected/Clients"))
);

const ClientsCreate = Loader(
  lazy(() => import("src/content/pages/protected/Clients/create"))
);

const ClientsUpdate = Loader(
  lazy(() => import("src/content/pages/protected/Clients/update"))
);

const UpdateClientsIssue = Loader(
  lazy(() => import("src/content/pages/protected/Clients/ClientsIssues/update"))
);
// Contacts
const Contacts = Loader(
  lazy(() => import("src/content/pages/protected/Contacts"))
);

const ContactsCreate = Loader(
  lazy(() => import("src/content/pages/protected/Contacts/create"))
);

const ContactsUpdate = Loader(
  lazy(() => import("src/content/pages/protected/Contacts/update"))
);

// Insurances
const Insurances = Loader(
  lazy(() => import("src/content/pages/protected/Insurances"))
);

const InsurancesCreate = Loader(
  lazy(() => import("src/content/pages/protected/Insurances/create"))
);

const InsurancesUpdate = Loader(
  lazy(() => import("src/content/pages/protected/Insurances/update"))
);

// Users
const Users = Loader(lazy(() => import("src/content/pages/protected/Users")));

const UsersUpdate = Loader(
  lazy(() => import("src/content/pages/protected/Users/update"))
);

// Place of Services
const PlaceOfServices = Loader(
  lazy(() => import("src/content/pages/protected/PlaceOfServices"))
);

const PlaceOfServicesCreate = Loader(
  lazy(() => import("src/content/pages/protected/PlaceOfServices/create"))
);

const PlaceOfServicesUpdate = Loader(
  lazy(() => import("src/content/pages/protected/PlaceOfServices/update"))
);

// Pay Periods
const PayPeriods = Loader(
  lazy(() => import("src/content/pages/protected/PayPeriods"))
);

const PayPeriodsCreate = Loader(
  lazy(() => import("src/content/pages/protected/PayPeriods/create"))
);

const PayPeriodsUpdate = Loader(
  lazy(() => import("src/content/pages/protected/PayPeriods/update"))
);

// Procedure Codes
const ProcedureCodes = Loader(
  lazy(() => import("src/content/pages/protected/ProcedureCodes"))
);

const ProcedureCodesCreate = Loader(
  lazy(() => import("src/content/pages/protected/ProcedureCodes/create"))
);

const ProcedureCodesUpdate = Loader(
  lazy(() => import("src/content/pages/protected/ProcedureCodes/update"))
);

// Procedure Modifiers
const ProcedureModifiers = Loader(
  lazy(() => import("src/content/pages/protected/ProcedureModifiers"))
);

const ProcedureModifiersCreate = Loader(
  lazy(() => import("src/content/pages/protected/ProcedureModifiers/create"))
);

const ProcedureModifiersUpdate = Loader(
  lazy(() => import("src/content/pages/protected/ProcedureModifiers/update"))
);

// Appointment Move History
const AppointmentMoveHistories = Loader(
  lazy(() => import("src/content/pages/protected/AppointmentMoveHistories"))
);

// Appointments
const Appointments = Loader(
  lazy(() => import("src/content/pages/protected/Appointments"))
);

// Appointments Submitted By Provider
const AppointmentsSubmittedByProvider = Loader(
  lazy(
    () => import("src/content/pages/protected/AppointmentsSubmittedByProvider")
  )
);
const UpdateAppointmentSubmittedByProvider = Loader(
  lazy(
    () =>
      import(
        "src/content/pages/protected/AppointmentsSubmittedByProvider/update"
      )
  )
);

const AppointmentsCancelledWithFee = Loader(
  lazy(() => import("src/content/pages/protected/AppointmentsCancelledWithFee"))
);

const AppointmentsFollowUpNeeded = Loader(
  lazy(() => import("src/content/pages/protected/AppointmentsFollowUpNeeded"))
);

const UpdateAppointmentsFollowUpNeeded = Loader(
  lazy(
    () =>
      import("src/content/pages/protected/AppointmentsFollowUpNeeded/update")
  )
);

const UpdateCancelledAppointmentWithFee = Loader(
  lazy(
    () =>
      import("src/content/pages/protected/AppointmentsCancelledWithFee/update")
  )
);

const AppointmentsAwaitingPayment = Loader(
  lazy(() => import("src/content/pages/protected/AppointmentsAwaitingPayment"))
);

const AppointmentsFinalizedPayment = Loader(
  lazy(() => import("src/content/pages/protected/AppointmentsFinalizedPayment"))
);

const UpdateAppointmentsFinalizedPayment = Loader(
  lazy(() => import("src/content/pages/protected/AppointmentsFinalizedPayment/update"))
);

// Payouts
const Payouts = Loader(
  lazy(() => import("src/content/pages/protected/Payouts"))
);

const PayoutsView = Loader(
  lazy(() => import("src/content/pages/protected/Payouts/view"))
);

const AppointmentsCreate = Loader(
  lazy(() => import("src/content/pages/protected/Appointments/create"))
);

const AppointmentsUpdate = Loader(
  lazy(() => import("src/content/pages/protected/Appointments/update"))
);

const BeamingInfo = Loader(
  lazy(() => import("src/content/pages/protected/BeamingInfo/index"))
);

// Diagnosis
const Diagnosis = Loader(
  lazy(() => import("src/content/pages/protected/Diagnoses"))
);

const DiagnosisCreate = Loader(
  lazy(() => import("src/content/pages/protected/Diagnoses/create"))
);

const DiagnosisUpdate = Loader(
  lazy(() => import("src/content/pages/protected/Diagnoses/update"))
);

// Diagnosis
const IssuesReasons = Loader(
  lazy(() => import("src/content/pages/protected/IssuesReasons"))
);

const IssuesReasonsCreate = Loader(
  lazy(() => import("src/content/pages/protected/IssuesReasons/create"))
);

const IssuesReasonsUpdate = Loader(
  lazy(() => import("src/content/pages/protected/IssuesReasons/update"))
);

// Login
const Login = Loader(lazy(() => import("src/content/pages/Login")));

// Warnings
const Warnings = Loader(
  lazy(() => import("src/content/pages/protected/Warnings"))
);

import { PublicRoute } from "src/guards/PublicRoute";
import { ProtectedRoute } from "src/guards/ProtectedRoute";
const UpdateAppointmentsAwaitingPayment = Loader(
  lazy(
    () => import("./content/pages/protected/AppointmentsAwaitingPayment/update")
  )
);
const AppointmentsDisapproved = Loader(
  lazy(() => import("src/content/pages/protected/AppointmentsDisapproved"))
);

const UpdateAppointmentsDisapproved = Loader(
  lazy(() => import("src/content/pages/protected/AppointmentsDisapproved/update"))
);

const routes: RouteObject[] = [
  {
    path: "login",
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    path: "",
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/appointments",
        element: <Appointments />,
      },
      {
        path: "/appointments-submitted-by-provider",
        element: <AppointmentsSubmittedByProvider />,
      },
      {
        path: "/appointments-cancelled-with-fee",
        element: <AppointmentsCancelledWithFee />,
      },
      {
        path: "/appointments-follow-up-needed",
        element: <AppointmentsFollowUpNeeded />,
      },
      {
        path: "/appointments-follow-up-needed/update/:id",
        element: <UpdateAppointmentsFollowUpNeeded />,
      },
      {
        path: "/appointments-cancelled-with-fee/update/:id",
        element: <UpdateCancelledAppointmentWithFee />,
      },
      {
        path: "/appointments-awaiting-payment",
        element: <AppointmentsAwaitingPayment />,
      },
      {
        path: "/appointments-awaiting-payment/update/:id",
        element: <UpdateAppointmentsAwaitingPayment />,
      },
      {
        path: "/appointments-finalized-payment",
        element: <AppointmentsFinalizedPayment />,
      },
      {
        path: "/appointments-finalized-payment/update/:id",
        element: <UpdateAppointmentsFinalizedPayment />,
      },
      {
        path: "/appointments-disapproved",
        element: <AppointmentsDisapproved />,
      },
      {
        path: "/appointments-disapproved/update/:id",
        element: <UpdateAppointmentsDisapproved />,
      },
      {
        path: "/payouts",
        element: <Payouts />,
      },
      {
        path: "/payouts/:id",
        element: <PayoutsView />,
      },
      {
        path: "/providers",
        element: <Providers />,
      },
      {
        path: "/providers/:page",
        element: <Providers />,
      },
      {
        path: "/providers/create",
        element: <ProvidersCreate />,
      },
      {
        path: "/providers/update/:id",
        element: <ProvidersUpdate />,
      },
      // clients
      {
        path: "/clients",
        element: <Clients />,
      },
      {
        path: "/clients/:page",
        element: <Clients />,
      },
      {
        path: "/clients/create",
        element: <ClientsCreate />,
      },
      {
        path: "/clients/update/:id",
        element: <ClientsUpdate />,
      },
      {
        path: "/clients/issues/update/:issueId",
        element: <UpdateClientsIssue />,
      },
      // contacts
      {
        path: "/contacts",
        element: <Contacts />,
      },
      {
        path: "/contacts/:page",
        element: <Contacts />,
      },
      {
        path: "/contacts/create",
        element: <ContactsCreate />,
      },
      {
        path: "/contacts/update/:id",
        element: <ContactsUpdate />,
      },
      // insurances
      {
        path: "/insurances",
        element: <Insurances />,
      },
      {
        path: "/insurances/create",
        element: <InsurancesCreate />,
      },
      {
        path: "/insurances/update/:id",
        element: <InsurancesUpdate />,
      },
      // users
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/users/:page",
        element: <Users />,
      },
      {
        path: "/users/update/:id",
        element: <UsersUpdate />,
      },
      // place of services
      {
        path: "/place-of-services",
        element: <PlaceOfServices />,
      },
      {
        path: "/place-of-services/create",
        element: <PlaceOfServicesCreate />,
      },
      {
        path: "/place-of-services/update/:id",
        element: <PlaceOfServicesUpdate />,
      },
      // procedure codes
      {
        path: "/procedure-codes",
        element: <ProcedureCodes />,
      },
      {
        path: "/procedure-codes/create",
        element: <ProcedureCodesCreate />,
      },
      {
        path: "/procedure-codes/update/:id",
        element: <ProcedureCodesUpdate />,
      },
      // pay periods
      {
        path: "/pay-periods",
        element: <PayPeriods />,
      },
      {
        path: "/pay-periods/create",
        element: <PayPeriodsCreate />,
      },
      {
        path: "/pay-periods/update/:id",
        element: <PayPeriodsUpdate />,
      },
      // procedure modifiers
      {
        path: "/procedure-modifiers",
        element: <ProcedureModifiers />,
      },
      {
        path: "/procedure-modifiers/create",
        element: <ProcedureModifiersCreate />,
      },
      {
        path: "/procedure-modifiers/update/:id",
        element: <ProcedureModifiersUpdate />,
      },
      // appointments
      {
        path: "/appointment-move-histories/:page",
        element: <AppointmentMoveHistories />,
      },
      // appointments
      {
        path: "/appointments/:page",
        element: <Appointments />,
      },
      {
        path: "/appointments/create",
        element: <AppointmentsCreate />,
      },
      {
        path: "/appointments/update/:id",
        element: <AppointmentsUpdate />,
      },
      // submitted by provider appointments
      {
        path: "/appointments-submitted-by-provider/update/:id",
        element: <UpdateAppointmentSubmittedByProvider />,
      },
      // diagnosis
      {
        path: "/diagnosis",
        element: <Diagnosis />,
      },
      {
        path: "/diagnosis/create",
        element: <DiagnosisCreate />,
      },
      {
        path: "/diagnosis/update/:id",
        element: <DiagnosisUpdate />,
      },
      // diagnosis
      {
        path: "/issue-reasons",
        element: <IssuesReasons />,
      },
      {
        path: "/issue-reasons/create",
        element: <IssuesReasonsCreate />,
      },
      {
        path: "/issue-reasons/update/:id",
        element: <IssuesReasonsUpdate />,
      },
      // beaming-info
      {
        path: "/beaming-info",
        element: <BeamingInfo />,
      },
      // {
      //   path: 'overview',
      //   element: <Navigate to="/" replace />
      // },
      // {
      //   path: 'status',
      //   children: [
      //     {
      //       path: '',
      //       element: <Navigate to="404" replace />
      //     },
      //     {
      //       path: '404',
      //       element: <Status404 />
      //     },
      //     {
      //       path: '500',
      //       element: <Status500 />
      //     },
      //     {
      //       path: 'maintenance',
      //       element: <StatusMaintenance />
      //     },
      //     {
      //       path: 'coming-soon',
      //       element: <StatusComingSoon />
      //     }
      //   ]
      // },
      {
        path: "/warnings",
        element: <Warnings />,
      },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
  {
    path: "dashboards",
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="crypto" replace />,
      },
      {
        path: "crypto",
        element: <Crypto />,
      },
      {
        path: "messenger",
        element: <Messenger />,
      },
    ],
  },
  {
    path: "management",
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="transactions" replace />,
      },
      {
        path: "transactions",
        element: <Transactions />,
      },
      {
        path: "profile",
        children: [
          {
            path: "",
            element: <Navigate to="details" replace />,
          },
          {
            path: "details",
            element: <UserProfile />,
          },
          {
            path: "settings",
            element: <UserSettings />,
          },
        ],
      },
    ],
  },
  {
    path: "/components",
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="buttons" replace />,
      },
      {
        path: "buttons",
        element: <Buttons />,
      },
      {
        path: "modals",
        element: <Modals />,
      },
      {
        path: "accordions",
        element: <Accordions />,
      },
      {
        path: "tabs",
        element: <Tabs />,
      },
      {
        path: "badges",
        element: <Badges />,
      },
      {
        path: "tooltips",
        element: <Tooltips />,
      },
      {
        path: "avatars",
        element: <Avatars />,
      },
      {
        path: "cards",
        element: <Cards />,
      },
      {
        path: "forms",
        element: <Forms />,
      },
    ],
  },
];

export default routes;
