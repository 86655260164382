import React, { useContext, useState } from 'react'
import { client as initialClient } from '../data'
import { Client } from '../models';

// ---------- ProviderContext ----------
type ClientSetter = (provider: Client) => void;
const ClientContext = React.createContext<Client | undefined>(undefined);
const ClientSetterContext = React.createContext<ClientSetter | undefined>(
  undefined
);

// ---------- Hooks ----------
function useClient(): Client {
  const provider = useContext(ClientContext);
  if (provider === undefined) {
    /* istanbul ignore next */
    throw new Error('useClient must be used within a ClientContextClient');
  }
  return provider;
}

function useClientSetter(): ClientSetter {
  const setClient = useContext(ClientSetterContext);
  if (setClient === undefined) {
    throw new Error(
      'useClientSetter must be used within a ClientContextClient'
    );
  }
  return setClient;
}

// ---------- ClientContextClient ----------
const ClientContextProvider: React.FC = ({ children }) => {
  const [provider, setClient] = useState<Client>(initialClient);

  return (
    <ClientContext.Provider value={provider}>
      <ClientSetterContext.Provider value={setClient}>
        {children}
      </ClientSetterContext.Provider>
    </ClientContext.Provider>
  );
};

export { ClientContextProvider, useClient, useClientSetter };