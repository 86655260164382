import {useIsAuthenticated} from 'react-auth-kit';
import { Navigate, useLocation } from "react-router-dom";

export function ProtectedRoute({ children }) {
    const isAuthenticated = useIsAuthenticated()
    const location = useLocation();

    return isAuthenticated() ? (
        children
    ) : (
        <Navigate
            to="/login"
            state={{ from: location }}
        />
    );
}