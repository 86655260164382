import { AppointmentSubmittedByProvider } from "../models";

export const appointmentSubmittedByProvider: AppointmentSubmittedByProvider = {
  doctor: null,
  client: null,
  start_at: null,
  end_at: null,
  provider_submitted_at: null,
  soap: {},
  procedure_codes: [
    {
      id: null,
      modifier_a: null,
      modifier_b: null,
      units: 1,
      charge: null,
      payout: null,
      max_charge: null,
    },
  ],
  type: 1,
  charge_data: {},
  charge_data_placeholder: {},
  diagnoses: [],
  is_insurance: false,
  follow_up_note: "",
  disapproved_reason: "",
};
