import {useRoutes} from 'react-router-dom';
import router from 'src/router';
import './App.scss'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import {CssBaseline} from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import {ConfirmProvider} from "material-ui-confirm";

function App() {
  const content = useRoutes(router);

  return (
    <ConfirmProvider>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline/>
          {content}
        </LocalizationProvider>
      </ThemeProvider>
    </ConfirmProvider>
  );
}

export default App;
